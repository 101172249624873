/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./node_modules/bootstrap/dist/css/bootstrap.min.css"

// Implement Reddit & Quora Pixels
export const onClientEntry = () => {
  // Skip if running on server-side
  if (typeof window === "undefined") {
    return
  }

  // Add Reddit Pixel
  const redditScript = document.createElement("script")
  redditScript.innerHTML = `
    !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_g128jh3fgwm9');rdt('track', 'PageVisit');
  `
  document.head.appendChild(redditScript)

  // Add Quora Pixel
  const quoraScript = document.createElement("script")
  quoraScript.innerHTML = `
    !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
    qp('init', '90a508fb5d2b414797421c5a3cd41257');
    qp('track', 'ViewContent');
  `
  document.head.appendChild(quoraScript)

  // Add Quora noscript pixel (fallback)
  const quoraNoScript = document.createElement("noscript")
  const quoraPixelImg = document.createElement("img")
  quoraPixelImg.height = "1"
  quoraPixelImg.width = "1"
  quoraPixelImg.style.display = "none"
  quoraPixelImg.src =
    "https://q.quora.com/_/ad/90a508fb5d2b414797421c5a3cd41257/pixel?tag=ViewContent&noscript=1"
  quoraNoScript.appendChild(quoraPixelImg)
  document.body.appendChild(quoraNoScript)

  // Add Twitter Conversion Tracking
  const twitterScript = document.createElement("script")
  twitterScript.innerHTML = `
    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
    twq('config','orqc0');
  `
  document.head.appendChild(twitterScript)
}

// ?cmpid=67424358da24427795a374d0&utm_source={r}&utm_medium={r}&utm_campaign={r}&twclid=wasif-test
